import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import { subscribe, unsubscribe, publish } from 'pubsub-js';
// import LottiePlayer from './ui/LottiePlayer';
import LogoRM from './LogoRM';
import LogoRMTypo from '../images/record-makers-logo-typo.inline.svg';
import LottiePlayer from './ui/LottiePlayer';
import LocaleSwitcher from './ui/LocaleSwitcher';
import SearchSanity from './ui/SanitySearch';
import CartMenuItem from './shop/CartMenuItem';
import MenuNav from './MenuNav';
import MenuSocial from './MenuSocial';
// import Modal from './ui/Modal';
import BannerMessage from './ui/BannerMessage';
import BtnMgmt from './ui/BtnMgmt';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { _localizeField, _localizeText } from 'utils/utils';
import usePageContext from 'context/PageContext';
// import Switch from './ui/Switch';

let _prevScrollTop: number = 0;

const Header: React.FC = () => {
  const {
    settings: { banner, menuNav, menuSocial, animations },
  } = useSiteMetadata();
  const { location } = usePageContext();
  const [direction, setDirection] = useState<string>('');
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [bannerIn, setBannerIn] = useState<boolean>(banner.display);
  const [isNav, setIsNav] = useState<boolean>(false);

  // console.log(banner);
  useEffect(() => {
    _onScroll();
    window.addEventListener('scroll', _onScroll);

    const tokenRoute = subscribe('ROUTE_UPDATE', (e) => {
      console.log(e);
      _onScroll();
      setIsNav(false);
    });

    const tokenSearch = subscribe('TOGGLE_SEARCH', () => {
      setIsSearch(true);
    });

    const tokenBanner = subscribe('CLOSE_BANNER', (e) => {
      console.log(e);
      setBannerIn(false);
    });

    const tokenTheme = subscribe(
      'TOGGLE_DARK_THEME',
      (e: Event, dark: boolean) => {
        // console.log('header', e, { dark });
        // document.body.classList.toggle('dark-theme', dark);
        setTimeout(() => {
          if (dark) {
            // console.log(document.body.classList);
            document.body.classList.add('dark-theme');
          } else {
            document.body.classList.remove('dark-theme');
          }
        }, 150);
      },
    );

    const tokenLottieEnd = subscribe('LOTTIE_ANIMATION_COMPLETE', () => {});

    return () => {
      window.removeEventListener('scroll', _onScroll);
      unsubscribe(tokenRoute);
      unsubscribe(tokenSearch);
      unsubscribe(tokenBanner);
      unsubscribe(tokenLottieEnd);
      unsubscribe(tokenTheme);
    };
  }, []);

  useEffect(() => {
    // console.log(location.pathname.includes('management'));
    if (location && location.pathname.includes('management')) {
      publish('TOGGLE_DARK_THEME', true);
    } else {
      publish('TOGGLE_DARK_THEME', false);
    }
  }, [location]);

  useEffect(() => {
    if (isSearch) publish('MODAL', true);
  }, [isSearch]);

  const _onScroll = () => {
    // console.log(window.scrollY);
    if (window.scrollY === 0) {
      // console.log('window.scrollY === 0');
      document.documentElement.classList.add('is-top');
    } else {
      // console.log('window.scrollY !== 0');
      document.documentElement.classList.remove('is-top');
    }
    setDirection(window.scrollY > 5 ? 'down' : '');
    // if (window.scrollY > 5) {
    //   setDirection('down');
    // } else {

    // }
  };

  const _toggleSearch = () => {
    publish('TOGGLE_SEARCH');
  };

  const _onLogoClick = (e: MouseEvent) => {
    publish('ONOMATOPOEIA', {
      icon: 'explosion',
      coords: {
        x: e.pageX,
        y: e.pageY,
      },
    });
  };

  const animationsUrls = useMemo(() => {
    return animations.map((item) => item.asset.url);
  }, [animations]);
  // console.log(animationsUrls);

  return (
    <>
      <header
        className={clsx(
          'md:px-xl fixed- sticky w-full top-0 z-40 ',
          `is-${direction}`,
          bannerIn ? 'has-banner' : '',
        )}
      >
        <div className="inner z-10- pt-md">
          <Link
            to="/"
            className="logo-container mx-auto mb-md w-[180px] block relative"
            onClick={() => _onLogoClick}
            title="Record Makers"
          >
            <div className="logo">
              <LogoRM />
              <div className="absolute top-0">
                <LottiePlayer files={animationsUrls} />
              </div>
            </div>
            <div className="logo-typo">
              <LogoRMTypo />
            </div>
          </Link>

          <div className="px-sm md:px-0 hidden-sm">
            <SearchSanity focus={isSearch} />
          </div>
        </div>
      </header>
      <div className="navs fixed w-full top-0 z-50 h-header-height pointer-events-none ">
        {bannerIn && <BannerMessage input={_localizeField(banner.message)} />}
        <div className="p-sm pr-md md:p-md flex justify-between hidden-sm">
          <div className="nav-group pointer-events-auto">
            <Link
              to="/"
              className="logo-container inline-block- mx-auto mb-md w-[180px] block relative hidden-sm"
              onClick={() => _onLogoClick}
              title="Record Makers"
            >
              <LogoRM />
            </Link>
            <MenuNav input={menuNav} />
            {/* <BtnMgmt /> */}
          </div>
          <div className="nav-group pointer-events-auto flex flex-col items-end">
            <div className="mb-md- mb-sm">
              <LocaleSwitcher />
            </div>
            <div className="mb-md">
              <MenuSocial input={menuSocial} />
            </div>
            <CartMenuItem />
          </div>
        </div>

        <div className="sm-only pointer-events-auto z-20- nav-mobile">
          <button
            className={clsx('p-sm absolute top-0 left-0 z-20 capitalize ')}
            onClick={() => setIsNav(!isNav)}
          >
            {isNav ? _localizeText('close') : 'MENU'}
          </button>
          <div className="absolute right-0 top-0 z-20">
            <CartMenuItem />
          </div>
          <div className={clsx('menu-overlay p-sm', isNav ? 'is-active' : '')}>
            <div className="flex justify-between flex-wrap">
              <div className="w-1/2">
                <div className="nav-group pointer-events-auto">
                  <Link
                    to="/"
                    className="logo-container inline-block- mx-auto mb-md w-[180px] block relative hidden-sm"
                    onClick={() => _onLogoClick}
                    title="Record Makers"
                  >
                    <LogoRM />
                  </Link>
                  <MenuNav input={menuNav} />
                  {/* <BtnMgmt /> */}
                </div>
              </div>
              <div className="w-1/2 flex flex-col items-end">
                <div className="mb-md-">
                  <LocaleSwitcher />
                </div>
                <div className="mb-md">
                  <MenuSocial input={menuSocial} />
                </div>
              </div>
              <div className="w-full py-md">
                <SearchSanity focus={isSearch} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
