import { Link } from 'gatsby';
import React from 'react';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { _linkResolver, _localizeField } from '../utils/utils';
import SanityTexte from './ui/SanityTexte';
// import Logo from '../images/record-makers-logo.inline.svg';
import LogoRM from './LogoRM';
import Mailchimp from './ui/Mailchimp';

const Footer = () => {
  const {
    settings: { menuFooter, address },
  } = useSiteMetadata();
  return (
    <footer className="p-sm md:p-md relative">
      <div className="row bottom-xs">
        <div className="col-xs-12 col-md-4">
          <div className="group">
            <p>RECORD MAKERS © 2000 — {new Date().getFullYear()}</p>
            <SanityTexte input={_localizeField(address)} />
          </div>
        </div>
        <div className="col-xs-12 col-md-4">
          <div className="logo">
            <LogoRM />
          </div>
        </div>
        <div className="col-xs-12 col-md-4">
          <div className="mb-md ">
            <Mailchimp
              className="outline px-md py-xs"
              // action="https://buildingparis.us5.list-manage.com/subscribe/post?u=8a8b23b6691303402d5307d91&amp;id=0c368c3d5e"
              action=""
              fields={[
                {
                  name: 'EMAIL',
                  placeholder: 'Subscribe to our newsletter (enter your email)',
                  type: 'email',
                  required: true,
                },
              ]}
            />
          </div>
          <div className="group">
            <ul className="menu-footer flex flex-col md:flex-row items-end justify-end mb-md">
              {menuFooter.map((item, i) => (
                <li key={i}>
                  <Link to={_linkResolver(item.link)}>
                    {_localizeField(item.label)}
                  </Link>
                </li>
              ))}
            </ul>
            <div className="credits text-right">
              <p>
                Design{' '}
                <a
                  href="https://www.policestudio.fr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Police Studio
                </a>
                <br />
                Code{' '}
                <a
                  href="https://ahmedghazi.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ahmed Ghazi
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
