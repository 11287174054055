import React, { useState } from 'react';
import PropTypes from 'prop-types';
import jsonp from 'jsonp';
// import PropTypes from "prop-types";
// import styled, { css } from "styled-components"
import clsx from 'clsx';
import { publish } from 'pubsub-js';

type Field = {
  name: string;
  placeholder: string;
  type: string;
  required: boolean;
};
type Props = {
  className: string;
  action: string;
  fields: Field[];
};
const Mailchimp = (props: Props) => {
  const { messages } = Mailchimp.defaultProps;
  const { fields, action, className } = props;
  const [state, setState] = useState<Array<any>>([]);
  const [status, setStatus] = useState<string>('');

  const getButtonMsg = () => {
    switch (status) {
      case 'sending':
        return messages.sending;
      case 'success':
        return messages.success;
      case 'duplicate':
        return messages.duplicate;
      case 'empty':
        return messages.empty;
      case 'error':
        return messages.error;
      default:
        return messages.button;
    }
  };

  const handleSubmit = (evt) => {
    // alert("submit");
    evt.preventDefault();
    const values = fields
      .map((field) => {
        return `${field.name}=${encodeURIComponent(state[field.name])}`;
      })
      .join('&');
    const path = `${action}&${values}`;
    const url = path.replace('/post?', '/post-json?');
    // const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/;
    // console.log(path, url);
    const email = state['EMAIL'];
    // console.log(email);
    // return;
    validateEmail(email) ? sendData(url) : setStatus('empty');
    // !regex.test(email) ? setStatus("empty") : sendData(url);
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const sendData = (url) => {
    setStatus('sending');
    jsonp(url, { param: 'c' }, (err, data) => {
      // console.log(err);
      // console.log(data);
      if (data.msg.includes('already subscribed')) {
        setStatus('duplicate');
      } else if (err) {
        setStatus('error');
      } else if (data.result !== 'success') {
        setStatus('error');
      } else {
        setStatus('success');
        setTimeout(() => {
          publish('NEWSLETTER_TOGGLE', false);
        }, 2000);
      }
    });
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={clsx(className, 'mailchimp-form')}
      >
        <div className="flex justify-between items-center  w-full">
          <div className="inputs flex-2">
            {fields.map((input) => (
              <div key={input.name} className="w-full input-wrapper">
                <input
                  {...input}
                  onChange={({ target }) =>
                    setState({ [input.name]: target.value })
                  }
                  className="w-full"
                  // defaultValue={state[input.name]}
                />
              </div>
            ))}
          </div>
          <div className="">
            <button
              disabled={status === 'sending' || status === 'success'}
              type="submit"
              aria-label="submit"
              className={'buttonClassName button'}
            >
              <span>{getButtonMsg()}</span>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default Mailchimp;

Mailchimp.defaultProps = {
  messages: {
    sending: 'Envoi...',
    success: 'Merci!',
    error: 'Erreur :(',
    empty: 'E-mail vide.',
    duplicate: "Trop de tentatives d'inscription pour cette adresse e-mail",
    button: '',
  },
  buttonClassName: '',
  // styles: {
  //   sendingMsg: {
  //     color: "#0652DD"
  //   },
  //   successMsg: {
  //     color: "#009432"
  //   },
  //   duplicateMsg: {
  //     color: "#EE5A24"
  //   },
  //   errorMsg: {
  //     color: "#ED4C67"
  //   }
  // }
};

Mailchimp.propTypes = {
  action: PropTypes.string,
  messages: PropTypes.object,
  fields: PropTypes.array,
  styles: PropTypes.object,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
};
