import React, { useEffect, useState } from 'react';
import { _fetchFile } from 'utils/utils';
import styled from 'styled-components';
import { useSiteMetadata } from 'hooks/useSiteMetadata';
import useDeviceDetect from 'hooks/useDeviceDetect';

type Props = {};

const VerticalText = styled.div`
  /* transform: rotate(90deg) translateY(96%) translateX(50%);
  transform-origin: right bottom; */
  transform: translateY(50%);
  svg {
    transform: scaleX(-1);
  }
`;

const AmbientPlayer = (props: Props) => {
  // const file = '/audio/factory-and-machine.mp3';
  const {
    settings: { sounds },
  } = useSiteMetadata();
  const sound = sounds.filter((el) => el.action === 'ambient');
  const { isMobile } = useDeviceDetect();
  const [audioCtx, setAudioCtx] = useState<AudioContext>();
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer>();
  const [gainNode, setGainNode] = useState<GainNode>();
  // const [convolver, setConvolver] = useState<ConvolverNode>();
  const [source, setSource] = useState<AudioBufferSourceNode>();
  const [play, setPlay] = useState<boolean>(false);
  // console.log({ play });

  useEffect(() => {
    // console.log(sound);
    if (!sound) return;
    try {
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      setAudioCtx(new AudioContext());
    } catch (e) {
      console.log('Web Audio API is not supported in this browser');
    }

    // return () => {};
  }, []);

  useEffect(() => {
    if (isMobile) return;
    if (!audioCtx) return;
    // setConvolver(audioCtx.createConvolver());
    setGainNode(audioCtx.createGain());
    _loadSampleAndDecode(sound[0].file.asset.url);
    // const _sourceBuffer = await getAudioBuffers(audioCtx, file)
    // setAudioBuffer(_sourceBuffer);
  }, [audioCtx]);

  useEffect(() => {
    document.addEventListener('mousemove', _onMouseMove, false);
    document.addEventListener('keydown', _onKey, false);
    if (window.DeviceMotionEvent) {
      window.addEventListener('devicemotion', _onMotion, false);
    } else {
      console.log('DeviceMotionEvent is not supported');
    }
    return () => {
      document.removeEventListener('mousemove', _onMouseMove, false);
      document.removeEventListener('keydown', _onKey, false);
      window.removeEventListener('devicemotion', _onMotion, false);
    };
  }, [audioBuffer, source, play]);

  const _onKey = (e: KeyboardEvent) => {
    // console.log(e.key);
    if (e.key === 'a') setPlay(!play);
  };

  useEffect(() => {
    if (play) {
      _play();
    } else {
      _stop();
    }
  }, [play]);

  const _onMotion = (event) => {
    if (!source || !play) return;
    console.log(
      'Accelerometer: ' +
        event.accelerationIncludingGravity.x +
        ', ' +
        event.accelerationIncludingGravity.y +
        ', ' +
        event.accelerationIncludingGravity.z,
    );

    const centerX = window.innerWidth / 2;
    const relativePercX =
      Math.abs(event.accelerationIncludingGravity.x - centerX) / (centerX / 2) +
      0.5;
    source.playbackRate.value = relativePercX;
  };

  const _onMouseMove = (e: MouseEvent) => {
    if (!source || !play) return;

    const centerX = window.innerWidth / 2;
    const relativePercX = Math.abs(e.pageX - centerX) / (centerX / 2) + 0.5;

    source.playbackRate.value = relativePercX;
  };

  const _loadSampleAndDecode = (file: string) => {
    if (!audioCtx) return;
    _fetchFile(file, (audioData) => {
      // console.log(file);
      audioCtx.decodeAudioData(
        audioData,
        (buffer) => {
          // convolver?.buffer = buffer
          setAudioBuffer(buffer);
        },
        (err) => console.log(err),
      );
    });
  };

  const _play = async () => {
    if (!audioBuffer || !audioCtx || !gainNode) return;
    // console.log(audioCtx);
    const _source = audioCtx.createBufferSource();
    _source.loop = true;
    _source.buffer = audioBuffer;
    _source.connect(gainNode);

    gainNode.connect(audioCtx.destination);
    gainNode.gain.linearRampToValueAtTime(0.1, audioCtx.currentTime);
    gainNode.gain.value = 1;
    // _source.connect(audioCtx.destination);
    _source.start(0);
    setSource(_source);
  };

  const _stop = () => {
    source?.stop();
  };

  return (
    <VerticalText
      className="fixed bottom-1/2 right-0 p-md btn hidden-sm"
      onClick={() => setPlay(!play)}
    >
      <div className="inner- pointer-events-none">
        {/* Ambiant {play ? 'off' : 'on'} */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.0"
          width="30"
          height="30"
          viewBox="0 0 75 75"
        >
          <path
            d="M39.389,13.769 L22.235,28.606 L6,28.606 L6,47.699 L21.989,47.699 L39.389,62.75 L39.389,13.769z"
            strokeWidth={3}
            strokeLinecap="round"
            stroke="black"
            fill="none"
          />
          {play && (
            <path
              d="M48,27.6a19.5,19.5 0 0 1 0,21.4M55.1,20.5a30,30 0 0 1 0,35.6M61.6,14a38.8,38.8 0 0 1 0,48.6"
              strokeWidth={3}
              strokeLinecap="round"
              stroke="black"
              fill="none"
            />
          )}
        </svg>
      </div>
    </VerticalText>
  );
};

export default AmbientPlayer;
