exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ds-tsx": () => import("./../../../src/pages/ds.tsx" /* webpackChunkName: "component---src-pages-ds-tsx" */),
  "component---src-templates-page-album-tsx": () => import("./../../../src/templates/PageAlbum.tsx" /* webpackChunkName: "component---src-templates-page-album-tsx" */),
  "component---src-templates-page-artist-tsx": () => import("./../../../src/templates/PageArtist.tsx" /* webpackChunkName: "component---src-templates-page-artist-tsx" */),
  "component---src-templates-page-home-tsx": () => import("./../../../src/templates/PageHome.tsx" /* webpackChunkName: "component---src-templates-page-home-tsx" */),
  "component---src-templates-page-modulaire-tsx": () => import("./../../../src/templates/PageModulaire.tsx" /* webpackChunkName: "component---src-templates-page-modulaire-tsx" */),
  "component---src-templates-page-product-tsx": () => import("./../../../src/templates/PageProduct.tsx" /* webpackChunkName: "component---src-templates-page-product-tsx" */)
}

