import React, { useMemo } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { GatsbyImageDataArgs, getGatsbyImageData } from 'gatsby-source-sanity';
import { sanityConfig } from '../../../config/sanity';
import { SanityImageAsset } from 'graphql-types';
import styled from 'styled-components';
import clsx from 'clsx';

type Props = {
  asset: SanityImageAsset | any;
  caption: string;
  objectFit?: string;
  width?: number;
};

/*
 needs placeholder blurred
*/
const ContainerImage = styled.div`
  &.is-cover {
    figure,
    .gatsby-image-wrapper {
      height: 100%;
      img {
        object-fit: cover;
      }
    }
  }
  &.is-contain {
    img {
      object-fit: contain;
    }
  }
  height: ${(props) => (props.objectFit === 'cover' ? '100%' : 'auto')};
  img[data-placeholder-image] {
    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }
`;

//ImageAsset | ImageObject | ImageRef | string | null | undefined;
const SanityImageUI = ({
  asset,
  caption,
  objectFit = 'contain',
  width = 2000,
}: Props) => {
  const options: GatsbyImageDataArgs = {
    placeholder: 'blurred', //placeholder?: 'blurred' | 'dominantColor' | 'none';
    fit: 'fillmax',
    layout: 'fullWidth',
    width: width,
    // maxWidth: width,
    // formats: ['auto', 'webp'],
    // formats: ['webp'], //['auto', 'webp'],
  };
  const imageData = useMemo(() => {
    return getGatsbyImageData(asset, options, sanityConfig);
  }, [asset]);
  // console.log(imageData);

  return (
    <ContainerImage
      objectFit={objectFit}
      className={clsx(objectFit === 'cover' ? 'is-cover' : 'is-contain')}
    >
      <figure>
        {imageData && (
          <GatsbyImage
            image={imageData}
            alt={caption || 'some alt'}
            loading="lazy"
            // className="w-full-"
            objectFit={objectFit}
            imgStyle={
              {
                // objectFit: objectFit,
              }
            }
          />
        )}
      </figure>
    </ContainerImage>
  );
};

export default SanityImageUI;
