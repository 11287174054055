// import { useContext } from "react";
import locales from '../../config/i18n';
import useLocale from 'context/LocaleContext';
// import useLocale from "../contexts/LocaleWrapper";

export function fileNameByUrl(url) {
  const decoded = decodeURIComponent(url);
  return decoded.substring(decoded.lastIndexOf('/') + 1);
}

export const _slugify = (raw) => raw.replace(/ /g, '-').toLowerCase();

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export const _getLocale = () => {
  const { locale } = useLocale();
  return locale;
};

export function _localizeField(field) {
  // console.log(field);
  const { locale } = useLocale();
  // console.log(locale);
  if (!field) return '';
  return field && field[locale] ? field[locale] : field['en'];
}

export const _localizeText = (text) => {
  const { locale } = useLocale();
  return locales[locale] && locales[locale][text]
    ? locales[locale][text]
    : text;
};

export const _linkResolver = (node) => {
  if (!node || !node.slug) return '/';
  // console.log(node._type);

  switch (node._type) {
    case 'album':
      return `/album/${node.slug.current}`;
    case 'product':
      return `/product/${node.slug.current}`;
    case 'artist':
      return `/artist/${node.slug.current}`;
    default:
      return `/${node.slug.current}`;
  }
};

export const getCookie = (cname) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const get_youtube_video_id_by_url = (url) => {
  // var regExp =
  //   /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  // var match = url.match(regExp);
  // return match && match[7].length === 11 ? match[7] : false;
  const reg =
    /^(https?:)?(\/\/)?((www\.|m\.)?youtube(-nocookie)?\.com\/((watch)?\?(feature=\w*&)?vi?=|embed\/|vi?\/|e\/)|youtu.be\/)([\w\-]{10,20})/i;
  const match = url.match(reg);
  if (match) {
    return match[9];
  } else {
    return null;
  }
};

export const get_youtube_video_thumbnail_by_url = (url) => {
  // console.log(url);
  const ID = get_youtube_video_id_by_url(url);
  return `https://i.ytimg.com/vi/${ID}/hqdefault.jpg`;
};

export const _minMax = (num: number, min: number, max: number): number => {
  return Math.min(Math.max(num, min), max);
};

export const _date = (raw) => {
  const locale = _getLocale();
  const { ogLang } = locales[locale] || 'en-US';
  // console.log({ ogLang });
  return new Date(raw).toLocaleDateString(ogLang, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
};
export const _dateDMY = (raw, sep = '.') => {
  const date = new Date(raw);
  const day = date.toLocaleDateString('en', {
    day: '2-digit',
  });
  const month = date.toLocaleDateString('en', {
    month: '2-digit',
  });
  const year = date.getFullYear();
  return `${day}${sep}${month}${sep}${year}`;
};

export const _excerptify = (text, len = 10) => {
  if (!text) return '';
  let output = '';
  const blocks = text.filter((el) => el._type === 'block');
  if (blocks) {
    blocks.forEach((el) => {
      // console.log(el);
      if (el._type === 'block' && output === '') {
        if (el.children.length > 0) {
          for (let i = 0; i < len; i++) {
            if (el.children[i] && el.children[i].text !== '') {
              output += el.children[i].text;
            }
          }
        }
        // if (el.children.length > 0 && el.children[0].text !== '') {
        //   output = el.children[0].text.substring(0, len) + '...';
        // }
      }
    });
  }
  // let output = text[0].children[0].text;
  // if (output.length > 10) output = output.substring(0, 100) + '...';

  return output;
};

export const _pad2 = (n) => {
  return (n < 10 ? '0' : '') + n;
};

export const _isTouchDevice = () => {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};

export const _fetchFile = (url: string, cb: Function) => {
  fetch(url)
    .then((response) => response.arrayBuffer())
    // .then(function(resp) {
    //     return resp.blob();
    //})
    .then(function (audioBuffer) {
      cb(audioBuffer);
    });
};
