import React from 'react';
import PortableText from '@sanity/block-content-to-react';
import SanityImageUI from './SanityImageUI';
import Embed from './Embed';
// import VideoEmbed from "./ui/VideoEmbed";
import { Link } from 'gatsby';
import { _linkResolver } from '../../utils/utils';
import clsx from 'clsx';

const SanityTexte = ({ input, columns = false }) => {
  // console.log(input);
  const serializers = {
    types: {
      block(props) {
        // console.log(props);
        switch (props.node.style) {
          case 'h2':
            return <h2>{props.children}</h2>;
          case 'text-lg':
            return <p className="text-lg">{props.children}</p>;
          case 'text-xl':
            return <p className="text-xl">{props.children}</p>;
          default:
            return <p>{props.children}</p>;
        }
      },
      image: ({ node }) => {
        return <SanityImageUI asset={node} caption="" />;
      },
      embed: ({ node }) => {
        return <Embed input={node} />;
      },
      // videoEmbed: ({ node }) => {
      //   return <VideoEmbed input={node.url} />;
      // },
    },

    marks: {
      align_left: ({ children }) => (
        <span className="text-left block">{children}</span>
      ),
      align_center: ({ children }) => (
        <span className="text-center block">{children}</span>
      ),
      align_justify: ({ children }) => (
        <span className="text-justify block">{children}</span>
      ),
      sup: ({ children }) => <sup>{children}</sup>,

      link: ({ mark, children }) => {
        // Read https://css-tricks.com/use-target_blank/
        // console.log(mark);
        const { blank, href } = mark;
        return blank ? (
          <a href={href} target="_blank" rel="noopener, noreferrer">
            {children}
          </a>
        ) : (
          <a href={href}>{children}</a>
        );
      },
      linkInternal: ({ mark, children }) => {
        // console.log(children, mark);
        return (
          <Link
            className="btn- btn--regular-"
            to={_linkResolver(mark.reference)}
          >
            {children}
          </Link>
        );
      },
    },
  };
  // console.log(input)
  return (
    <div className={clsx('text text-left ', columns ? 'columns-2' : '')}>
      <PortableText blocks={input} serializers={serializers} />
    </div>
  );
};

export default SanityTexte;
