import React, { useRef } from 'react';
// import SanityTexte from './SanityTexte';
import styled from 'styled-components';
import { publish } from 'pubsub-js';
import MarqueeUI from '../modules/MarqueeUI';
import { SanityLocaleTextFieldSelector } from '../../../graphql-types';

type Props = {
  input: SanityLocaleTextFieldSelector;
};

const Container = styled.div`
  --bg-rgb: 0, 0, 0;
  --text-color: 255, 255, 255;
  background-color: rgb(var(--bg-rgb));
  color: rgb(var(--text-color));
  min-height: 2.53333333em;
  z-index: 20;
  pointer-events: all;
  .btn-close {
    transform: translateY(-0.1em);
    color: rgb(var(--text-color));
  }
  .text {
    max-width: none;
  }
  .text::before,
  .text::after {
    content: '';
    position: absolute;
    top: 0;
    width: 2em;
    height: 100%;
    z-index: 10;
  }
  .text::before {
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(var(--bg-rgb), 1),
      rgba(var(--bg-rgb), 0)
    );
  }
  .text::after {
    right: 0;
    background: linear-gradient(
      90deg,
      rgba(var(--bg-rgb), 1),
      rgba(var(--bg-rgb), 0)
    );
    transform: rotate(180deg);
  }
  .text {
    text-align: center;
    white-space: nowrap;
  }
`;

const BannerMessage = ({ input }: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>();

  const _close = () => {
    // if (ref.current) ref.current.style.display = 'none';
    publish('CLOSE_BANNER');
  };
  // console.log(input);

  return (
    <Container ref={ref} className="banner-message p-sm pr-lg text-center ">
      <div className="text">
        <MarqueeUI input={input} />
      </div>
      <button
        className="btn-close absolute right-0 top-0 p-sm !text-black-"
        onClick={_close}
      >
        {'\uE075'}
      </button>
    </Container>
  );
};

export default BannerMessage;
