import React, { createContext, useContext, ReactNode, useState } from 'react';
import { PageProps } from 'gatsby';
// import { Script } from 'gatsby';

interface PageContextProps {
  location?: {
    pathname: string;
  };
  children: ReactNode;
  pageContext: object;
}

interface IWrapperContext {
  location?: {
    pathname: string;
  };
  pageContext: object;
  dark: boolean;
  toggleDark?: () => void;
}

const defaultState = {
  dark: false,
};

const WrapperContext = createContext<Partial<IWrapperContext>>({});

const PageContext = (props: PageContextProps) => {
  const { children, pageContext, location } = props;
  const [dark, setDark] = useState<boolean>(defaultState.dark);

  const toggleDark = () => {
    setDark(!dark);
  };

  return (
    <WrapperContext.Provider
      value={{ pageContext, location, dark, toggleDark }}
    >
      {children}
    </WrapperContext.Provider>
  );
};

// export default PageContext;
export { WrapperContext, PageContext };

export default function usePageContext() {
  return useContext(WrapperContext);
}
