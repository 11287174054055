import clsx from 'clsx';
import React from 'react';
import useLocale from '../../context/LocaleContext';
const locales = require('../../../config/i18n');
import styled from 'styled-components';

const Ul = styled.ul`
  li:first-child {
    &:after {
      content: '|';
      display: inline-block;
      padding: 0 0.2em;
      color: white;
    }
  }
`;

const LocaleSwitcher = () => {
  const { locale, dispatch } = useLocale();
  // console.log(locale);
  return (
    <Ul className="locale-switcher flex justify-end mb-05e">
      {Object.values(locales).map((item, i) => (
        <li
          key={`locale-${i.toString()}`}
          // className={i < Object.values(locales).length - 1 ? "mb-xs" : "mb-1e"}
        >
          <button
            onClick={() => dispatch(item.locale)}
            className={clsx(
              // "outline py-sm px-md",
              locale === item.locale ? 'is-active' : '',
            )}
          >
            {item.label}
          </button>
        </li>
      ))}
    </Ul>
  );
};

export default LocaleSwitcher;
