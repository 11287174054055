//const website = require('./website')

module.exports = {
  fr: {
    default: true,
    label: 'Fr',
    path: 'fr',
    locale: 'fr',
    siteLanguage: 'fr',
    ogLang: 'fr-FR',
    projects: 'Projets',
    about: 'À propos',
    contact: 'Contact',
    type: 'Type',
    nature: 'Nature',
    surface: 'Superficie',
    capacity: "Capacité d'accueil",
    nextProjects: 'Projets suivants',
    ctaProjets: 'Voir tous les projets',
    news: 'Actualité',
    search: 'Rechercher',
    searchResult: 'Résultat',
    searchResults: 'Résultats',
    discography: 'Discographie',
    buy: 'Acheter',
    cookieButtonText: "It's okay.",
    cookieDeclineButtonText: 'No, thanks.',
    cookieMessage:
      "We use cookies to enhance the user experience. Learn more in our <a href='https://www.dialogue-works.com/campaign/' target='_blank'>privacy settings.</a>",
    close: 'FERMER',
  },
  en: {
    default: false,
    label: 'En',
    path: 'en',
    locale: 'en',
    siteLanguage: 'en',
    ogLang: 'en-US',
    projects: 'Projects',
    about: 'About',
    contact: 'Contact',
    nextProjects: 'Next projects',
    ctaProjets: 'View all projects',
    news: 'News',
    search: 'Search',
    searchResult: 'Result',
    searchResults: 'Results',
    discography: 'Discography',
    buy: 'Buy',
    cookieButtonText: "It's okay.",
    cookieDeclineButtonText: 'No, thanks.',
    cookieMessage:
      "We use cookies to enhance the user experience. Learn more in our <a href='https://www.dialogue-works.com/campaign/' target='_blank'>privacy settings.</a>",
    close: 'CLOSE',
  },
};
