import clsx from 'clsx';
import { Link } from 'gatsby';
import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { subscribe, unsubscribe, publish } from 'pubsub-js';
// import useLocale from '../../context/LocaleContext';
import {
  _linkResolver,
  _localizeField,
  _localizeText,
} from '../../utils/utils';
// import Plus from '../../images/plus.inline.svg'

const wait = async (delay) => {
  return new Promise((resolve) => setTimeout(resolve, delay));
};

const Container = styled.div`
  /* border: 1px solid white;
  .bt,
  .bb {
    position: relative;
  }
  .bt::before,
  .bb::after {
    background-color: white;
  }
  .status {
    color: white;
    font-size: var(--text-sm);
    text-transform: uppercase;
    padding: 1rem 2rem;
  }
  .results-wrapper {

    &:empty {
      display: none;
    }
  } */
`;

const Form = styled.form`
  /* position: relative;

  &.outline::before {
    border-color: black;
  }
  .loader {
    position: absolute;
    height: 100%;
    width: var(--border-width);
    width: 3rem;
    filter: blur(3rem);
    background-color: #0000ff80;
    top: 0;
    left: 0;
    transform: translateX(100%);
    opacity: 0;
  }
  &.is-loading .loader {
    animation: kitt 2s linear 0s infinite alternate;
    opacity: 1;
  }
  input,
  button {
    padding: 0.9rem 2rem;

    line-height: unset;
    max-height: 3em;
  }
  input {
    background-color: transparent;
    flex: 2;
    ::placeholder {
      color: currentColor;
    }
    @media all and (max-width: 768px) {
      max-width: calc(100% - 9rem);
    }
  }
  button {
    cursor: pointer;
  }
  .action {
    min-width: 9rem;
    text-align: right;
  } */
`;

type IProps = {
  focus: boolean;
};

interface ResultItem {
  slug: {
    current: string;
  };
  image: string;
  title: string;
  _type: string;
}
interface IData extends Array<ResultItem> {}

interface IResultItem {
  input: ResultItem;
}

const ResultItem = ({ input }: IResultItem): JSX.Element => (
  <li className="bt py-1 px-2 ">
    <Link to={_linkResolver(input)}>
      <div className="flex justify-between items-center">
        <div className="header w-1/2 flex items-center">
          <img
            src={input.image}
            alt=""
            height="40"
            width="40"
            className="object-cover "
          />
          <h2 className="px-1e">{input.title}</h2>
        </div>
        <div className="metas w-1/2 capitalize flex justify-between">
          <div>{input._type}</div>
          <div>
            <i className="icon-arrow-e "></i>
          </div>
        </div>
      </div>
    </Link>
  </li>
);

const SearchSanity = ({ focus }: IProps): JSX.Element => {
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Array<object>>([]);
  const [error, setError] = useState<string>('');
  const [submited, setSubmited] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>();

  // const { locale } = useLocale();

  useEffect(() => {
    const tokenRoute = subscribe('ROUTE_UPDATE', () => {
      setSearch('');
    });
    return () => unsubscribe(tokenRoute);
  }, []);

  // useEffect(() => {
  //   if (inputRef?.current && window.innerWidth > 1080)
  //     inputRef?.current?.focus();
  // }, [focus]);

  useEffect(() => {
    publish('IS_LOADING', loading);
  }, [loading]);

  /**
   * RESET
   */
  useEffect(() => {
    if (search === '') {
      setData([]);
      setSubmited(false);
      document.body.classList.remove('is-search');
    } else {
      document.body.classList.add('is-search');
    }
  }, [search]);

  useEffect(() => {
    if (data && data.length > 0) {
      const btnSubmitBounding: DOMRect =
        inputRef?.current?.getBoundingClientRect();

      console.log(btnSubmitBounding);
      publish('ONOMATOPOEIA', {
        icon: 'paf',
        coords: {
          // x: btnSubmitBounding.x + btnSubmitBounding.width / 2,
          x: btnSubmitBounding.x + 150,
          y: btnSubmitBounding.y + btnSubmitBounding.height / 2,
        },
      });
    }
  }, [data]);

  const _onSubmit = async (evt: MouseEvent) => {
    evt.preventDefault();
    // console.log(evt, locale)
    // setSubmited(true)
    setLoading(true);
    setSubmited(true);
    setData([]);

    // await wait(20000);
    // return
    const body = { s: search };
    console.log(body);
    fetch('/api/search', {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .then((e) => e.json())
      .then((e: IData) => {
        console.log(e);
        setData(e);
      })
      .catch((e) => setError(e.message))
      .finally(() => setLoading(false));
  };

  const _reset = () => {
    setData([]);
    setSearch('');
    setSubmited(false);
    setError('');
    setLoading(false);
  };

  // console.log(data)
  const resultLabel =
    data.length > 1
      ? _localizeText('searchResults')
      : _localizeText('searchResult');

  const loadingLabel = _localizeText('loading');
  const noResultsLabel = _localizeText('noResults');
  // const pagetypeLabel = _localizeText('pagetype');
  // const festivalLabel = _localizeText('festival');

  const isSearching = search !== '' && submited && loading === false;
  return (
    <Container className="sanity-search hide-sb">
      <div className="sticky top-0 z-50 outline-">
        <Form
          onSubmit={_onSubmit}
          className={clsx(
            ' text-lg- outline is-rounded overflow-hidden ',
            // loading && 'outline--gradient',
            loading && 'is-loading',
          )}
        >
          <div className="loader"></div>
          <div className="flex justify-between">
            <input
              ref={inputRef}
              type="text"
              name="s"
              placeholder={`${_localizeText('search')} ...`}
              value={search}
              autoComplete="off"
              onChange={(e) => setSearch(e.target.value)}
              onMouseEnter={() => inputRef?.current?.focus()}
              className="pointer"
            />
            <div className="action">
              {!submited && (
                <button
                  type="submit"
                  className={clsx(
                    '',
                    submited && isSearching ? 'text-black-40 btn--submit' : '',
                  )}
                >
                  {'\uE073'}
                </button>
              )}
              {submited && (
                <button type="button" onClick={_reset}>
                  {'\uE075'}
                </button>
              )}
            </div>
          </div>
        </Form>
      </div>

      <div className="results-wrapper bg-black- overflow-y-auto hide-sb">
        {loading && (
          <div className="status status--loading uppercase py-1 px-2 text-sm bt-">
            {loadingLabel}...
          </div>
        )}
        {isSearching && error && (
          <div className="status status--error uppercase py-1 px-2 text-sm bt-">
            {error}
          </div>
        )}

        {isSearching && (
          <div className="results pt-1e- bg-white- text-white-  ">
            <>
              {data.length === 0 && (
                <div className="status uppercase py-1 px-2 text-sm bt-">
                  <span>{noResultsLabel}</span>
                </div>
              )}

              {data.length > 0 && (
                <div className="header flex uppercase py-1 px-2 text-sm bt-">
                  <div className="label w-1/2">
                    <span>
                      {data.length} {resultLabel}
                    </span>
                  </div>
                </div>
              )}
            </>
            <ul>
              {/* <pre>{JSON.stringify(data)}</pre> */}
              {/* {data.length && <pre>{JSON.stringify(data)}</pre>} */}
              {data.length &&
                data.map((item: ResultItem, i: number) => (
                  <ResultItem key={i} input={item} />
                ))}
            </ul>
          </div>
        )}
      </div>
    </Container>
  );
};

export default SearchSanity;
