import React, { ReactNode, useEffect, createContext, useContext } from 'react';
import Header from './Header';
import Footer from './Footer';
import Cursor from './ui/Cursor';
import Onomatopoeia from './ui/Onomatopoeia';
import { ShopWrapper } from './shop/ShopContext';
import usePageContext from 'context/PageContext';
import { publish } from 'pubsub-js';
import CookieConsent from './ui/CookieConsent';
import Sampler from './ui/sampler';
import AmbientPlayer from './ui/AmbientPlayer/index';
import useDeviceDetect from 'hooks/useDeviceDetect';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = (props) => {
  // console.log(props);
  const { children, location } = props;
  const { isMobile } = useDeviceDetect();
  // const { location, dark } = usePageContext();

  useEffect(() => {
    _onResize();
    setTimeout(() => {
      _onResize();
    }, 300);

    window.addEventListener('resize', _onResize);
    // document.addEventListener('click', _debugClick);

    return () => {
      window.removeEventListener('resize', _onResize);
      // document.removeEventListener('click', _debugClick);
    };
  }, []);

  useEffect(() => {
    _onResize();
    setTimeout(() => {
      _onResize();
    }, 300);
  }, [location]);

  // const _debugClick = (e: MouseEvent) => {
  //   // const
  //   publish('ONOMATOPOEIA', {
  //     icon: 'boum',
  //     coords: {
  //       x: e.pageX,
  //       y: e.pageY,
  //     },
  //   });
  // };

  const _onResize = () => {
    const wh = window.innerHeight;

    document.documentElement.style.setProperty('--app-height', wh + 'px');

    const header = document.querySelector('header');
    let headerBounding = {} || { height: 50 };
    if (header) {
      headerBounding = header.getBoundingClientRect();

      document.documentElement.style.setProperty(
        '--header-height',
        headerBounding.height + 'px',
      );
    }
    // console.log('here', document.body);
    setTimeout(() => {
      // document.body.classList.add('reveal');
    }, 150);
  };

  return (
    <div id="page">
      <div>
        <ShopWrapper>
          <Header />
          <main className="z-10 pt-header-height-">
            <div className="pt-lg">{children}</div>
          </main>
        </ShopWrapper>
        <Footer />
        <CookieConsent />
      </div>
      <Cursor size={15} color="white" />
      <Onomatopoeia defaultValue="boum" />
      {/* <Sampler /> */}
      <AmbientPlayer />
    </div>
  );
};

export default Layout;
