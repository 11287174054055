import React from 'react';
import { Link } from 'gatsby';
import { publish } from 'pubsub-js';
import { _linkResolver, _localizeField } from 'utils/utils';
import Switch from './ui/Switch';

type Props = {
  input: any;
};

const MenuNav = ({ input }: Props): JSX.Element => {
  // const _toggleSearch = () => {
  //   publish('TOGGLE_SEARCH');
  // };

  return (
    <nav>
      <ul>
        {input.map((item, i) => (
          <li key={i} className={`relative  menu-${item.link.slug.current}`}>
            {/* {item.link.slug.current === 'management' && (
              <div
                className="pl-sm absolute left-full pointer-events-none hidden-sm"
                style={{
                  top: '.2em',
                }}
              >
                <Switch size={14} event="TOGGLE_DARK_THEME" />
              </div>
            )} */}
            <Link to={_linkResolver(item.link)}>
              {_localizeField(item.label)}
            </Link>
          </li>
        ))}
        {/* <li>
          <button onClick={_toggleSearch}>Search</button>
        </li> */}
      </ul>
    </nav>
  );
};

export default MenuNav;
