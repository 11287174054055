import React from 'react';
import { _linkResolver, _localizeField } from 'utils/utils';
import { SanityLinkIcon } from '../../graphql-types';
// import SanityImageUI from './ui/SanityImageUI';

type Props = {
  input: SanityLinkIcon[];
};

const MenuSocial = ({ input }: Props) => {
  return (
    <nav className="menu-social text-md">
      <ul className="text-right ">
        {input.map((item, i) => (
          <li key={i} className="text-right">
            <a
              href={item.link || ''}
              target="_blank"
              rel="noopener noreferrer"
              title={item.label || ''}
              className="pb-04e block"
            >
              {/* <pre>{JSON.stringify(item)}</pre> */}
              <div className="pointer-events-none- flex justify-end">
                {item.label && item.label !== 'http' && !item.icon && (
                  <i className={` icon-${item.label.toLowerCase()}`}></i>
                )}
                {item.icon && item.icon.asset && item.icon.asset?.url && (
                  <img
                    src={item.icon.asset?.url}
                    alt={(item.label && item.label.toLowerCase()) || 'icon'}
                    width={13}
                  />
                )}
                {item.label && item.label === 'http' && <span>http</span>}
              </div>
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default MenuSocial;
