import { useStaticQuery, graphql } from 'gatsby';

export const useSiteMetadata = () => {
  const { site, settings, sitemap } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            siteTitle
            siteDescription
            siteUrl
            image
            ogLanguage
            author
          }
        }
        sitemap: allSitePage {
          nodes {
            path
          }
        }
        settings: sanitySettings {
          banner {
            display
            message: _rawMessage(resolveReferences: { maxDepth: 10 })
          }
          menuNav {
            label {
              ...localeString
            }
            link {
              slug {
                current
              }
              _type
            }
          }
          menuSocial {
            link
            label
            icon: _rawIcon(resolveReferences: { maxDepth: 10 })
          }
          animations {
            asset {
              url
            }
          }
          menuFooter {
            label {
              ...localeString
            }
            link {
              slug {
                current
              }
              _type
            }
          }
          address: _rawAddress(resolveReferences: { maxDepth: 10 })
          productInfos: _rawProductInfos(resolveReferences: { maxDepth: 10 })
          message404: _rawMessage404(resolveReferences: { maxDepth: 10 })
          messageCookies: _rawMessageCookies(
            resolveReferences: { maxDepth: 10 }
          )
          sounds {
            action
            file {
              asset {
                url
              }
            }
          }
        }
      }
    `,
  );
  // console.log(site);
  return {
    buildTime: site.buildTime,
    site: site.siteMetadata,
    sitemap: sitemap,
    settings: settings,
  };
};
