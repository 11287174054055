// /* global Snipcart:false */
import React from 'react';
import clsx from 'clsx';
import useShop from './ShopContext';

const CartMenuItem = () => {
  const { cart } = useShop();
  const hasItemsInCart = cart && cart.items?.count > 0;
  // console.log(cart);
  return (
    <div
      className={clsx(
        'cart transition-all',
        hasItemsInCart
          ? 'translate-x-0 opacity-1'
          : 'translate-x-full opacity-0',
      )}
    >
      <button
        className="snipcart-checkout text-md btn btn--rounded"
        aria-label="View Cart"
      >
        {!hasItemsInCart && <i className="icon-cart"></i>}
        {hasItemsInCart && (
          <>
            <i className="icon-cart--with-products"></i>
            {/* <sup className="text-sm absolute"> ({cart.items.count})</sup> */}
          </>
        )}
      </button>
    </div>
  );
};

export default CartMenuItem;
