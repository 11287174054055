import React from 'react';
import Marquee from 'react-fast-marquee';
import styled from 'styled-components';

const Container = styled.div`
  .item {
    padding: 0 0.2em;
    /* line-height: 1.2; */
    /* padding-top: 0.05em; */
    /* padding-bottom: 0.1em; */
  }
`;

const MarqueeUI = ({ input }) => {
  return (
    <Container className="">
      <Marquee gradient={false} speed={40} play={true} className="">
        {new Array(20).fill(0).map((v, i) => (
          <div key={i} className="item px-05e">
            {input}
          </div>
        ))}
      </Marquee>
    </Container>
  );
};

export default MarqueeUI;
