import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { subscribe, unsubscribe } from 'pubsub-js';

type Props = {
  defaultValue: string;
};
type Coords = {
  x: number;
  y: number;
};
interface IONOMATOPOEIA {
  icon: string;
  coords: Coords;
}

const variants = {
  expanded: {
    opacity: [0, 1, 0],
    scale: [0, 1, 2],
    rotate: [0, 10, 20],
    y: [0, -25, -50],
  },
  collapsed: {
    opacity: 0,
    transition: {
      duration: 0,
    },
  },
};

const Onomatopoeia = ({ defaultValue }: Props) => {
  const [transform, setTransform] = useState<String>('translate(0vw, 0vh)');
  const [value, setValue] = useState<String>(defaultValue);
  const [clicked, setClicked] = useState<Boolean>(false);
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const token = subscribe(
      'ONOMATOPOEIA',
      (e: string, data: IONOMATOPOEIA) => {
        // console.log(e, data);

        setValue(data.icon);
        _update(data.coords);
        variants.expanded.rotate[1] = Math.random() * 30;
        setClicked(true);
      },
    );

    return () => {
      unsubscribe(token);
    };
  }, [clicked]);

  const _update = (coords: Coords) => {
    if (!ref.current) return;

    const { width, height } = ref.current.getBoundingClientRect();
    const left = coords.x - width / 2;
    const top = coords.y - height / 2;
    // console.log(left, top);
    setTransform(`translate(${left}px, ${top}px)`);
  };

  return (
    <div
      className="onomatopeia fixed will-change-transform text-lg top-0 left-0 pointer-events-none z-50"
      ref={ref}
      style={{
        transform: transform,
        zIndex: 999999,
      }}
    >
      <motion.div
        initial="collapsed"
        // className="z-0 overflow-hidden"
        // animate={controls}

        variants={variants}
        animate={clicked ? 'expanded' : 'collapsed'}
        transition={{
          duration: 1,
          ease: [0.17, 0.67, 0.83, 0.67],
          times: [0, 0.1, 1],
        }}
        onAnimationComplete={() => setClicked(false)}
      >
        <i className={`icon-${value}`}></i>
      </motion.div>
    </div>
  );
};

export default Onomatopoeia;
