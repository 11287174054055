import clsx from 'clsx';
import React from 'react';
import ReactPlayer from 'react-player';
import { SanityEmbed } from '../../../graphql-types';
type Props = {
  input: SanityEmbed;
};

const Embed = ({ input }: Props): JSX.Element => {
  const playerConfig = {
    youtube: {
      playerVars: {
        controls: 1,
        disablekb: 1,
        enablejsapi: 1,
        iv_load_policy: 3,
        modestbranding: 1,
        cc_load_policy: 0,
        showinfo: 0,
        rel: 0,
        origin: 'https://www.recordmakers.com',
      },
      embedOptions: {
        host: 'https://www.youtube-nocookie.com',
      },
    },
    vimeo: {
      title: 'false',
      // background: true,
      // controls: false,
    },
  };
  const isVideo = input.url && input.url?.indexOf('yout') > 0;

  return (
    <div className="embed mb-md-">
      {input.iframe && (
        <div dangerouslySetInnerHTML={{ __html: input.iframe }} />
      )}
      {input.url && (
        <div className={clsx(isVideo ? 'aspect-video' : '')}>
          <ReactPlayer
            url={input.url}
            width="100%"
            height="100%"
            config={playerConfig}
          />
        </div>
      )}
      {/* <pre>{JSON.stringify(input)}</pre> */}
      {/* <iframe src={input.url} frameBorder="0" width="100%"></iframe> */}
    </div>
  );
};

export default Embed;
