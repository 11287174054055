import './src/styles/tailwind.css';
import './src/styles/index.scss';
import React from 'react';
import PubSub from 'pubsub-js';
import type { GatsbyBrowser } from 'gatsby';
import { LocaleContext } from 'context/LocaleContext';
import { PageContext } from './src/context/PageContext';
import Layout from './src/components/Layout';
import { ShopWrapper } from 'components/shop/ShopContext';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  // console.log(element);
  return (
    <PageContext {...props}>
      <LocaleContext>
        <Layout>{element}</Layout>
      </LocaleContext>
    </PageContext>
  );
};

export const onClientEntry = () => {
  const isTouch = 'ontouchstart' in window ? true : false;

  if (isTouch) {
    document.documentElement.classList.add('touch');
  } else {
    document.documentElement.classList.add('no-touch');
  }
  console.log('onClientEntry');
  setTimeout(() => {
    document.documentElement.classList.add('doc-ready');
  }, 500);
};

export const onRouteUpdate = ({ location }) => {
  //console.log('new pathname', location.pathname)
  PubSub.publish('ROUTE_UPDATE');
};
