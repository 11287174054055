import React from 'react';
// import ReactCookieConsent from 'react-cookie-consent';
import { CookieNotice } from 'gatsby-cookie-notice';
// import { _localizeField, _localizeText } from '../../core/utils';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';
// import SanityTexte from "../SanityTexte"
// import { _getLocalizedText } from '../../lib/helpers'
// import { publish } from 'pubsub-js';
import SanityTexte from './SanityTexte';
import { _localizeField, _localizeText } from 'utils/utils';

const CookieConsent = () => {
  const { settings } = useSiteMetadata();
  // console.log(_localizeField(settings.messageCookie))
  return (
    // <ReactCookieConsent
    //   // debug={true}
    //   containerClasses="cookie-consent "
    //   contentClasses="cookie-consent--content"
    //   disableStyles={true}
    //   location="bottom"
    //   buttonText={_localizeText('cookieButtonText')}
    //   declineButtonText={_localizeText('cooieDeclineButtonText')}
    //   // cookieName='gatsby-gdpr-google-analytics'
    //   acceptOnScroll={false}
    //   onAccept={(acceptedByScrolling) => publish('COOKIE_CONSENT_ACCEPT')}
    // >

    //   <SanityTexte input={_localizeField(settings.messageCookies)} />
    // </ReactCookieConsent>
    <CookieNotice>
      <h4>This websites uses cookies.</h4>
      <SanityTexte input={_localizeField(settings.messageCookies)} />
    </CookieNotice>
  );
};

export default CookieConsent;
