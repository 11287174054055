import React, { FC, useEffect, useState, useRef } from 'react';
import Lottie from 'react-lottie';
import { subscribe, unsubscribe, publish } from 'pubsub-js';
import clsx from 'clsx';
import useDeviceDetect from 'hooks/useDeviceDetect';

type Props = {
  files: String[];
};

const LottiePlayer: FC<Props> = ({ files }) => {
  const [animation, setAnimation] = useState<String>('');
  const [visible, setVisible] = useState<boolean>(false);
  const animationRef = useRef<Lottie>(null);
  const { isMobile } = useDeviceDetect();
  const width = isMobile ? 140 : 180;
  const height = isMobile ? 104 : 134;

  useEffect(() => {
    let controller: AbortController = new AbortController();
    _fetchRandomAnimation(controller);

    const tokenRoute = subscribe('ROUTE_UPDATE', () => {
      _fetchRandomAnimation(controller);
    });

    const tokenOnomatopeia = subscribe('ONOMATOPOEIA', () => {
      _fetchRandomAnimation(controller);
    });

    return () => {
      controller.abort();
      unsubscribe(tokenRoute);
      unsubscribe(tokenOnomatopeia);
    };
  }, []);

  const _fetchRandomAnimation = (controller) => {
    const rand = Math.floor(Math.random() * files.length);
    let item = files[rand];

    if (!item) item = files[0];

    const fetchData = async () => {
      const data = await fetch(item.toString(), { signal: controller.signal });
      const json = await data.json();
      if (json.layers) {
        setAnimation(json);
        setVisible(true);
      }
    };

    fetchData()
      // make sure to catch any error
      .catch(console.error);
  };

  const _setAnimationByType = (type: string) => {
    // animationRef.current?.play();
    // console.log(animationRef);
    // Or set a specific startFrame and endFrame with:
    // animationRef.current?.play(1, 30);
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const eventListeners: EventListener[] = [
    {
      eventName: 'complete',
      callback: () => {
        // console.log('the animation completed:');
        setVisible(false);
      },
    },
  ];

  return (
    <div
      className={clsx(
        'lottie-player transition-opacity',
        visible ? 'opacity-100' : 'opacity-0',
      )}
    >
      <Lottie
        ref={animationRef}
        options={defaultOptions}
        width={width}
        height={height}
        eventListeners={eventListeners}
      />
    </div>
  );
};

export default LottiePlayer;
